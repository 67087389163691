#edit-appointment-modal {
  overflow: hidden;
  z-index: 9;
  max-width: 600px;
  border-top: 4px solid var(--heavy-color);
  max-height: 30vh;
}

#edit-appointment-modal .modal-content {
  overflow-x: hidden;
  padding-right: 34px;
}

#appointment_name {
  text-align: right;
}

.has-text-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.has-text-left strong {
  font-weight: 700;
  font-size: 1.6rem;
}

.has-text-left span {
  flex: 0 0 auto;
}

#appointment_consultorio {
  flex: 0 0 20%;
}

#edit-appointment-cancel-button {
  color: var(--heaviest-color);
}

#efectivo-appointment-button {
  background-color: var(--heaviest-color);
  margin: 0 17px 0 17px;
  border-radius: 5px;
}

#tarjeta-appointment-button {
  background-color: var(--heaviest-secondary-color);
  margin: 0 0 0 17px;
  border-radius: 5px;
}

#efectivo-appointment-button:hover,
#tarjeta-appointment-button:hover {
  color: #fff;
}
