body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spinner-outer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: calc(90vh - 6rem);
}

.sk-spinner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1rem;
}

/*
  Declare color variables
*/

:root {
  --light-shadow-black-color: hsla(0, 0%, 0%, 0.2);
  --light-shadow-white-color: hsla(0, 0%, 100%, 0.2);
  --light-color: #d9edfa;
  --medium-color: #a6d0ec;
  --heavy-color: #78b2d8;
  --heaviest-color: #2e7db2;
  --light-color-two: #92ebeb;
  --medium-color-two: #64d8d8;
  --light-color-three: #dcf4c7;
  --medium-color-three: #c3eaa1;
  --light-color-four: #d3fbe8;
  --medium-color-four: #a4efcb;
  --light-color-five: #d9dafb;
  --medium-color-five: #aeb1ef;
  --light-color-six: #d2f9f8;
  --medium-color-six: #a1ebe6;
  --light-color-seven: #feced7;
  --medium-color-seven: #fc9caf;
  --light-color-eight: #ffeaae;
  --medium-color-eight: #ffe087;
  --light-color-nine: #ffc3ae;
  --medium-color-nine: #ffa687;
  --light-color-ten: #cb8aa8;
  --medium-color-ten: #aa5a7e;
  --light-color-fourteen: #ffe6d1;
  --medium-color-fourteen: #facfad;
  --light-secondary-color: #b4e9ee;
  --heaviest-secondary-color: #52b3bc;
  --light-red-color: #fedae3;
  --heavy-red-color: #f25a80;
  --heaviest-red-color: #c43200;
  --light-grey-color: #f2f2f2;
  --light-white-color: #fffffffb;
  --normal-text-color: #58575e;
  --light-text-color: #64727b;
  --heavy-text-color: #333333;
  --light-grey-border-color: #64727b40;
  background-color: rgba(211, 236, 242, 0.2);
}

.hide {
  display: none;
}

.show {
  display: block;
}

body {
  margin-bottom: 20vh;
}

body.loading {
  cursor: progress;
}

body,
button {
  font-family: "Nunito Sans", sans-serif !important;
  color: #58575e;
  color: var(--normal-text-color);
}

h1,
h2,
h3,
h4,
h5 {
  color: #333333;
  color: var(--heavy-text-color);
}

a {
  color: #58575e;
  color: var(--normal-text-color);
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: #333333;
  color: var(--heavy-text-color);
}

.nav-wrapper {
  min-height: 4rem;
  background-color: #fff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
}

.nav-wrapper .nav-links {
  flex: 1 1 auto;
}

.nav-wrapper .nav-links .right a:not(.btn) {
  font-weight: 400;
  color: var(--normal-text-color);
}

.nav-links a:hover {
  background-color: transparent !important;
  color: var(--heaviest-color) !important;
}

.nav-links a.current {
  font-weight: 700 !important;
  font-size: 0.95rem;
  box-shadow: inset 0 -2px 0 var(--heaviest-color);
  color: var(--heaviest-color) !important;
}

.nav-wrapper img {
  max-height: 3rem;
  margin-left: 2rem;
}

.nav-wrapper .brand-logo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .nav-wrapper img {
    margin-left: 0;
  }
}

.dashboard {
  width: 96%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sidebar {
  color: var(--heavy-text-color);
  flex: 0 0 30%;
  min-width: 320px;
}

.sidebar-no-dates-message {
  margin: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
}

.sidebar-general-info {
  overflow: hidden;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 2rem;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
}

.sidebar-general-info-appointments {
  margin: 1rem 2rem;
  display: flex;
  align-items: center;
}

.sidebar-general-info-money-made,
.sidebar-general-info-money-give {
  margin-bottom: 0.5rem;
}

.sidebar-general-info-money-hide {
  padding: 0.4rem 0.8rem;
  border-radius: 5px;
  color: #212121;
}

.sidebar-general-info-money-hide.background {
  background-color: #ccc;
}

.sidebar-general-info-money-hide:hover {
  cursor: pointer;
}

.sidebar-general-info-money-hide-label {
  font-size: 0.8rem;
  display: none;
}

.sidebar-general-info-money-hide-label.show {
  display: block;
}

.sidebar-general-info-money {
  margin: 1rem 2rem;
}

.sidebar-general-info-money > span {
  line-height: 1.7;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-general-info-money-give-number,
.sidebar-general-info-money-made-number {
  display: none;
  font-weight: 600;
}

.sidebar-general-info-money-give-number.show,
.sidebar-general-info-money-made-number.show {
  display: block;
  font-weight: 600;
}

.sidebar-general-info-money-give-number {
  color: var(--heaviest-red-color);
}

.sidebar-general-info-money-made-number {
  color: #02b55f;
}

.sidebar-general-info-appointments-completed {
  color: var(--normal-text-color);
  font-size: 2.4rem;
  font-weight: 300;
  margin-right: 0.5rem;
}

.sidebar-general-info-appointments-total {
  color: var(--normal-text-color);
  font-size: 2.4rem;
  font-weight: 300;
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.sidebar-next-appointments {
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
  background-color: #e5e5e5;
}

.sidebar-appointments {
  margin: 1rem 2rem;
}

.sidebar h5 {
  background-color: #ffffff;
  color: var(--heavy-text-color);
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1.2rem;
  margin: 0 0 2rem 0;
  border-bottom: 1px solid var(--light-grey-border-color);
}

.sidebar-appointments-completed {
  font-size: 1.2rem;
}

.sidebar-appointments-completed.green {
  color: var(--heavy-red-color);
}

.sidebar-appointments-completed.red {
  color: var(--heavy-red-color);
}

.sidebar-appointments-left {
  font-size: 1.6rem;
  font-weight: 700;
  color: #52b3bc;
}

.sidebar-individual-appointment {
  background-color: #fff;
  overflow: hidden;
  border-style: inset;
  margin: 1rem;
  border-radius: 5px;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  padding: 0px 24px 0px 0px;
}

.sidebar-individual-appointment-buttons {
  background-color: #f8f9fa;
  width: 48px;
  display: flex;
  height: 120px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-right: 24px;
}

.sidebar-individual-appointment-buttons svg.no-hover * {
  fill: hsl(202, 12%, 46%) !important;
}

.sidebar-individual-appointment-buttons svg * {
  box-shadow: inset 0 1px 3px var(--light-shadow-black-color),
    0 -2px 0 var(--light-shadow-white-color);
  fill: hsl(202, 12%, 46%);
}

.sidebar-individual-appointment-buttons svg:hover *,
.sidebar-individual-appointment-buttons svg.selected * {
  fill: var(--heaviest-color);
}

.sidebar-individual-appointment-buttons svg.no-hover {
  cursor: auto !important;
}

.sidebar-individual-appointment-buttons svg:hover {
  cursor: pointer;
}

.sidebar-individual-appointment:first-of-type {
  margin-top: -1rem;
}

.sidebar-individual-appointment-details {
  padding: 1rem;
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.sidebar-individual-appointment-details-name {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.sidebar-individual-appointment-details-time {
  font-size: 0.8rem;
  color: var(--light-text-color);
  font-weight: 300;
}

.sidebar-individual-appointment-patient {
  margin: 0.2rem 0 0.8rem 0;
  color: var(--normal-text-color);
}

.doctor-select-wrapper {
  background-color: var(--heaviest-color);
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--light-grey-border-color);
  border-bottom: 0;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.doctor-select {
  max-width: 300px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8rem;
  width: 100%;
  color: var(--normal-text-color);
  background-color: white;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: none;
  border-radius: 4px;
}

.doctor-select-wrapper > div a {
  margin-right: 1rem;
}

.doctor-select-wrapper > div a path {
  fill: #ffffff;
}

.doctor-select > div {
  min-height: auto;
  background-color: inherit;
  border: 0;
}

.doctor-select > span {
  display: none;
  border: 0;
  outline: none;
}

.react-select__menu {
  z-index: 99999999999 !important;
}

#add-appointment-modal {
  overflow: hidden;
  z-index: 9;
  max-width: 600px;
  border-top: 4px solid var(--heavy-color);
}

#add-appointment-modal .modal-content {
  overflow-x: hidden;
}

#appointment_name {
  text-align: right;
}

.has-text-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.has-text-left strong {
  font-weight: 700;
  font-size: 1.6rem;
}

.has-text-left span {
  flex: 0 0 auto;
}

#appointment_consultorio {
  flex: 0 0 20%;
}

#add-appointment-cancel-button {
  color: var(--heaviest-color);
}

#add-appointment-button {
  background-color: var(--heaviest-color);
  margin: 0 17px 0 17px;
  border-radius: 5px;
}

#add-appointment-button:hover {
  color: #fff;
}

#add-doctor-modal {
  width: 70%;
  display: block;
  background-color: #ffffff;
  border-radius: 5px;
  height: 80vh;
  max-height: 80vh;
}

#add-doctor-modal .modal-content {
  overflow-x: hidden;
  padding-right: 34px;
}

.ScrollbarsCustom-track {
  right: 2px !important;
}

.select-input {
  font-family: "Nunito Sans", sans-serif;
  border: 0;
  box-shadow: none;
  border-radius: 4px;
}

.has-icon-centered {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.has-icon-centered > *:last-child {
  flex: 0 0 92%;
}

.select-input > div:first-of-type {
  border-color: var(--light-grey-border-color) !important;
  box-shadow: none !important;
}

.select-input input {
  max-height: 1.75rem;
}

.select-input input {
  border-bottom: none !important;
  box-shadow: none !important;
}

.input-field .prefix.active {
  color: var(--heaviest-color);
}

#add-doctor-cancel-button {
  color: var(--heaviest-color);
}

#add-doctor-button {
  background-color: var(--heaviest-color);
  margin: 0 17px 0 17px;
  border-radius: 5px;
}

#add-doctor-button:hover {
  color: #fff;
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid var(--heaviest-color);
  box-shadow: 0 1px 0 0 var(--heaviest-color);
}

input:not([type]):disabled,
input:not([type])[readonly="readonly"],
input[type="text"]:not(.browser-default):disabled,
input[type="text"]:not(.browser-default)[readonly="readonly"],
input[type="password"]:not(.browser-default):disabled,
input[type="password"]:not(.browser-default)[readonly="readonly"],
input[type="email"]:not(.browser-default):disabled,
input[type="email"]:not(.browser-default)[readonly="readonly"],
input[type="url"]:not(.browser-default):disabled,
input[type="url"]:not(.browser-default)[readonly="readonly"],
input[type="time"]:not(.browser-default):disabled,
input[type="time"]:not(.browser-default)[readonly="readonly"],
input[type="date"]:not(.browser-default):disabled,
input[type="date"]:not(.browser-default)[readonly="readonly"],
input[type="datetime"]:not(.browser-default):disabled,
input[type="datetime"]:not(.browser-default)[readonly="readonly"],
input[type="datetime-local"]:not(.browser-default):disabled,
input[type="datetime-local"]:not(.browser-default)[readonly="readonly"],
input[type="tel"]:not(.browser-default):disabled,
input[type="tel"]:not(.browser-default)[readonly="readonly"],
input[type="number"]:not(.browser-default):disabled,
input[type="number"]:not(.browser-default)[readonly="readonly"],
input[type="search"]:not(.browser-default):disabled,
input[type="search"]:not(.browser-default)[readonly="readonly"],
textarea.materialize-textarea:disabled,
textarea.materialize-textarea[readonly="readonly"] {
  color: rgba(0, 0, 0, 0.42);
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
}

input:not([type]):disabled + label,
input:not([type])[readonly="readonly"] + label,
input[type="text"]:not(.browser-default):disabled + label,
input[type="text"]:not(.browser-default)[readonly="readonly"] + label,
input[type="password"]:not(.browser-default):disabled + label,
input[type="password"]:not(.browser-default)[readonly="readonly"] + label,
input[type="email"]:not(.browser-default):disabled + label,
input[type="email"]:not(.browser-default)[readonly="readonly"] + label,
input[type="url"]:not(.browser-default):disabled + label,
input[type="url"]:not(.browser-default)[readonly="readonly"] + label,
input[type="time"]:not(.browser-default):disabled + label,
input[type="time"]:not(.browser-default)[readonly="readonly"] + label,
input[type="date"]:not(.browser-default):disabled + label,
input[type="date"]:not(.browser-default)[readonly="readonly"] + label,
input[type="datetime"]:not(.browser-default):disabled + label,
input[type="datetime"]:not(.browser-default)[readonly="readonly"] + label,
input[type="datetime-local"]:not(.browser-default):disabled + label,
input[type="datetime-local"]:not(.browser-default)[readonly="readonly"] + label,
input[type="tel"]:not(.browser-default):disabled + label,
input[type="tel"]:not(.browser-default)[readonly="readonly"] + label,
input[type="number"]:not(.browser-default):disabled + label,
input[type="number"]:not(.browser-default)[readonly="readonly"] + label,
input[type="search"]:not(.browser-default):disabled + label,
input[type="search"]:not(.browser-default)[readonly="readonly"] + label,
textarea.materialize-textarea:disabled + label,
textarea.materialize-textarea[readonly="readonly"] + label {
  color: rgba(0, 0, 0, 0.42);
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid var(--heaviest-color);
  box-shadow: 0 1px 0 0 var(--heaviest-color);
}

input:not([type]):focus:not([readonly]) + label,
input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime-local"]:not(.browser-default):focus:not([readonly])
  + label,
input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
input[type="number"]:not(.browser-default):focus:not([readonly]) + label,
input[type="search"]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: var(--heaviest-color);
}

input:not([type]):focus.valid ~ label,
input[type="text"]:not(.browser-default):focus.valid ~ label,
input[type="password"]:not(.browser-default):focus.valid ~ label,
input[type="email"]:not(.browser-default):focus.valid ~ label,
input[type="url"]:not(.browser-default):focus.valid ~ label,
input[type="time"]:not(.browser-default):focus.valid ~ label,
input[type="date"]:not(.browser-default):focus.valid ~ label,
input[type="datetime"]:not(.browser-default):focus.valid ~ label,
input[type="datetime-local"]:not(.browser-default):focus.valid ~ label,
input[type="tel"]:not(.browser-default):focus.valid ~ label,
input[type="number"]:not(.browser-default):focus.valid ~ label,
input[type="search"]:not(.browser-default):focus.valid ~ label,
textarea.materialize-textarea:focus.valid ~ label {
  color: var(--heaviest-color);
}

input:not([type]):focus.invalid ~ label,
input[type="text"]:not(.browser-default):focus.invalid ~ label,
input[type="password"]:not(.browser-default):focus.invalid ~ label,
input[type="email"]:not(.browser-default):focus.invalid ~ label,
input[type="url"]:not(.browser-default):focus.invalid ~ label,
input[type="time"]:not(.browser-default):focus.invalid ~ label,
input[type="date"]:not(.browser-default):focus.invalid ~ label,
input[type="datetime"]:not(.browser-default):focus.invalid ~ label,
input[type="datetime-local"]:not(.browser-default):focus.invalid ~ label,
input[type="tel"]:not(.browser-default):focus.invalid ~ label,
input[type="number"]:not(.browser-default):focus.invalid ~ label,
input[type="search"]:not(.browser-default):focus.invalid ~ label,
textarea.materialize-textarea:focus.invalid ~ label {
  color: #f44336;
}

input:not([type]).validate + label,
input[type="text"]:not(.browser-default).validate + label,
input[type="password"]:not(.browser-default).validate + label,
input[type="email"]:not(.browser-default).validate + label,
input[type="url"]:not(.browser-default).validate + label,
input[type="time"]:not(.browser-default).validate + label,
input[type="date"]:not(.browser-default).validate + label,
input[type="datetime"]:not(.browser-default).validate + label,
input[type="datetime-local"]:not(.browser-default).validate + label,
input[type="tel"]:not(.browser-default).validate + label,
input[type="number"]:not(.browser-default).validate + label,
input[type="search"]:not(.browser-default).validate + label,
textarea.materialize-textarea.validate + label {
  width: 100%;
}

/* Validation Sass Placeholders */
input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type="text"]:not(.browser-default),
input.valid[type="text"]:not(.browser-default):focus,
input.valid[type="password"]:not(.browser-default),
input.valid[type="password"]:not(.browser-default):focus,
input.valid[type="email"]:not(.browser-default),
input.valid[type="email"]:not(.browser-default):focus,
input.valid[type="url"]:not(.browser-default),
input.valid[type="url"]:not(.browser-default):focus,
input.valid[type="time"]:not(.browser-default),
input.valid[type="time"]:not(.browser-default):focus,
input.valid[type="date"]:not(.browser-default),
input.valid[type="date"]:not(.browser-default):focus,
input.valid[type="datetime"]:not(.browser-default),
input.valid[type="datetime"]:not(.browser-default):focus,
input.valid[type="datetime-local"]:not(.browser-default),
input.valid[type="datetime-local"]:not(.browser-default):focus,
input.valid[type="tel"]:not(.browser-default),
input.valid[type="tel"]:not(.browser-default):focus,
input.valid[type="number"]:not(.browser-default),
input.valid[type="number"]:not(.browser-default):focus,
input.valid[type="search"]:not(.browser-default),
input.valid[type="search"]:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid #4caf50;
  box-shadow: 0 1px 0 0 #4caf50;
}

input.invalid:not([type]),
input.invalid:not([type]):focus,
input.invalid[type="text"]:not(.browser-default),
input.invalid[type="text"]:not(.browser-default):focus,
input.invalid[type="password"]:not(.browser-default),
input.invalid[type="password"]:not(.browser-default):focus,
input.invalid[type="email"]:not(.browser-default),
input.invalid[type="email"]:not(.browser-default):focus,
input.invalid[type="url"]:not(.browser-default),
input.invalid[type="url"]:not(.browser-default):focus,
input.invalid[type="time"]:not(.browser-default),
input.invalid[type="time"]:not(.browser-default):focus,
input.invalid[type="date"]:not(.browser-default),
input.invalid[type="date"]:not(.browser-default):focus,
input.invalid[type="datetime"]:not(.browser-default),
input.invalid[type="datetime"]:not(.browser-default):focus,
input.invalid[type="datetime-local"]:not(.browser-default),
input.invalid[type="datetime-local"]:not(.browser-default):focus,
input.invalid[type="tel"]:not(.browser-default),
input.invalid[type="tel"]:not(.browser-default):focus,
input.invalid[type="number"]:not(.browser-default),
input.invalid[type="number"]:not(.browser-default):focus,
input.invalid[type="search"]:not(.browser-default),
input.invalid[type="search"]:not(.browser-default):focus,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea.invalid:focus,
.select-wrapper.invalid > input.select-dropdown,
.select-wrapper.invalid > input.select-dropdown:focus {
  border-bottom: 1px solid #f44336;
  box-shadow: 0 1px 0 0 #f44336;
}

input:not([type]).valid ~ .helper-text[data-success],
input:not([type]):focus.valid ~ .helper-text[data-success],
input:not([type]).invalid ~ .helper-text[data-error],
input:not([type]):focus.invalid ~ .helper-text[data-error],
input[type="text"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="text"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="text"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="text"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="password"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="password"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="password"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="password"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="email"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="email"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="email"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="email"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="url"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="url"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="url"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="url"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="time"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="time"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="time"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="time"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="date"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="date"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="date"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="date"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="datetime"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="datetime"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="datetime"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="datetime"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="datetime-local"]:not(.browser-default).valid
  ~ .helper-text[data-success],
input[type="datetime-local"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="datetime-local"]:not(.browser-default).invalid
  ~ .helper-text[data-error],
input[type="datetime-local"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="tel"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="tel"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="tel"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="tel"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="number"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="number"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="number"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="number"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="search"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="search"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="search"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="search"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
textarea.materialize-textarea.valid ~ .helper-text[data-success],
textarea.materialize-textarea:focus.valid ~ .helper-text[data-success],
textarea.materialize-textarea.invalid ~ .helper-text[data-error],
textarea.materialize-textarea:focus.invalid ~ .helper-text[data-error],
.select-wrapper.valid .helper-text[data-success],
.select-wrapper.invalid ~ .helper-text[data-error] {
  color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

input:not([type]).valid ~ .helper-text:after,
input:not([type]):focus.valid ~ .helper-text:after,
input[type="text"]:not(.browser-default).valid ~ .helper-text:after,
input[type="text"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="password"]:not(.browser-default).valid ~ .helper-text:after,
input[type="password"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="email"]:not(.browser-default).valid ~ .helper-text:after,
input[type="email"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="url"]:not(.browser-default).valid ~ .helper-text:after,
input[type="url"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="time"]:not(.browser-default).valid ~ .helper-text:after,
input[type="time"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="date"]:not(.browser-default).valid ~ .helper-text:after,
input[type="date"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="datetime"]:not(.browser-default).valid ~ .helper-text:after,
input[type="datetime"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="datetime-local"]:not(.browser-default).valid ~ .helper-text:after,
input[type="datetime-local"]:not(.browser-default):focus.valid
  ~ .helper-text:after,
input[type="tel"]:not(.browser-default).valid ~ .helper-text:after,
input[type="tel"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="number"]:not(.browser-default).valid ~ .helper-text:after,
input[type="number"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="search"]:not(.browser-default).valid ~ .helper-text:after,
input[type="search"]:not(.browser-default):focus.valid ~ .helper-text:after,
textarea.materialize-textarea.valid ~ .helper-text:after,
textarea.materialize-textarea:focus.valid ~ .helper-text:after,
.select-wrapper.valid ~ .helper-text:after {
  content: attr(data-success);
  color: var(--heaviest-color);
}

input:not([type]).invalid ~ .helper-text:after,
input:not([type]):focus.invalid ~ .helper-text:after,
input[type="text"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="text"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="password"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="password"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="email"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="email"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="url"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="url"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="time"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="time"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="date"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="date"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="datetime"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="datetime"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="datetime-local"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="datetime-local"]:not(.browser-default):focus.invalid
  ~ .helper-text:after,
input[type="tel"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="tel"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="number"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="number"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="search"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="search"]:not(.browser-default):focus.invalid ~ .helper-text:after,
textarea.materialize-textarea.invalid ~ .helper-text:after,
textarea.materialize-textarea:focus.invalid ~ .helper-text:after,
.select-wrapper.invalid ~ .helper-text:after {
  content: attr(data-error);
  color: #f44336;
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
  background-color: inherit;
}

.big-calendar-wrapper {
  min-height: 580px;
  flex: 0 0 65%;
  min-width: 320px;
  font-size: 14px;
}

.rbc-addons-dnd-resizable {
  width: 100%;
}

button:hover {
  cursor: pointer;
}

.not-business {
  background-color: var(--light-red-color);
}

.not-business:hover {
  cursor: pointer;
}

.rbc-timeslot-group {
  min-height: 50px;
}

.not-business:hover {
  cursor: not-allowed;
}

.rbc-day-slot .rbc-events-container {
  margin: 0;
}

.rbc-event strong {
  color: #0f1c20;
}

.rbc-event-content {
  display: flex;
  align-items: center;
}

.rbc-event-content > div {
  width: 100%;
  height: 100%;
}

/* Normal */

.rbc-event {
  min-width: 100px;
  padding: 0;
  color: var(--heavy-text-color);
  fill: var(--heavy-text-color);
  font-size: 0.9rem;
  font-weight: 300;
  border: 0 !important;
  border-radius: 0px !important;
  background-color: var(--light-color);
  box-shadow: 0px 1px 2px var(--light-grey-border-color);
  margin-bottom: 2px;
  min-height: 20px;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  z-index: 9;
}

.rbc-event:focus {
  outline: none;
}

.rbc-event-content > div.two {
  background-color: var(--light-color-two);
}

.rbc-event-content > div.three {
  background-color: var(--light-color-three);
}

.rbc-event-content > div.four {
  background-color: var(--light-color-four);
}

.rbc-event-content > div.five {
  background-color: var(--light-color-five);
}

.rbc-event-content > div.six {
  background-color: var(--light-color-six);
}

.rbc-event-content > div.seven {
  background-color: var(--light-color-seven);
}

.rbc-event-content > div.eight {
  background-color: var(--light-color-eight);
}

.rbc-event-content > div.nine {
  background-color: var(--light-color-nine);
}

.rbc-event-content > div.ten {
  background-color: var(--light-color-ten);
}

.rbc-event-content > div.fourteen {
  background-color: var(--light-color-fourteen);
}

/* Hover and finished*/

.rbc-event:hover {
  z-index: 9999999999;
}

.rbc-event:hover,
.rbc-event-content > div.finished {
  background-color: var(--medium-color) !important;
}

.rbc-event-content > div.two:hover,
.rbc-event-content > div.finished.two {
  background-color: var(--medium-color-two) !important;
}

.rbc-event-content > div.three:hover,
.rbc-event-content > div.finished.three {
  background-color: var(--medium-color-three) !important;
}

.rbc-event-content > div.four:hover,
.rbc-event-content > div.finished.four {
  background-color: var(--medium-color-four) !important;
}

.rbc-event-content > div.five:hover,
.rbc-event-content > div.finished.five {
  background-color: var(--medium-color-five) !important;
}

.rbc-event-content > div.six:hover,
.rbc-event-content > div.finished.six {
  background-color: var(--medium-color-six) !important;
}

.rbc-event-content > div.seven:hover,
.rbc-event-content > div.finished.seven {
  background-color: var(--medium-color-seven) !important;
}

.rbc-event-content > div.eight:hover,
.rbc-event-content > div.finished.eight {
  background-color: var(--medium-color-eight) !important;
}

.rbc-event-content > div.nine:hover,
.rbc-event-content > div.finished.nine {
  background-color: var(--medium-color-nine) !important;
}

.rbc-event-content > div.ten:hover,
.rbc-event-content > div.finished.ten {
  background-color: var(--medium-color-ten) !important;
}

.rbc-event-content > div.fourteen:hover,
.rbc-event-content > div.finished.fourteen {
  background-color: var(--medium-color-fourteen) !important;
}

/* Confirmed */

.rbc-event-content .confirmed {
  border-left: 0.25rem solid var(--medium-color) !important;
}

.rbc-event-content .confirmed.two {
  border-left: 0.25rem solid var(--medium-color-two) !important;
}

.rbc-event-content .confirmed.three {
  border-left: 0.25rem solid var(--medium-color-three) !important;
}

.rbc-event-content .confirmed.four {
  border-left: 0.25rem solid var(--medium-color-four) !important;
}

.rbc-event-content .confirmed.five {
  border-left: 0.25rem solid var(--medium-color-five) !important;
}

.rbc-event-content .confirmed.six {
  border-left: 0.25rem solid var(--medium-color-six) !important;
}

.rbc-event-content .confirmed.seven {
  border-left: 0.25rem solid var(--medium-color-seven) !important;
}

.rbc-event-content .confirmed.eight {
  border-left: 0.25rem solid var(--medium-color-eight) !important;
}

.rbc-event-content .confirmed.nine {
  border-left: 0.25rem solid var(--medium-color-nine) !important;
}

.rbc-event-content .confirmed.ten {
  border-left: 0.25rem solid var(--medium-color-ten) !important;
}

.rbc-event-content .confirmed.fourteen {
  border-left: 0.25rem solid var(--medium-color-fourteen) !important;
}

.rbc-event-label {
  display: none;
}

.rbc-event-preview {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 4px;
}

.rbc-event-preview svg {
  height: 1rem;
  width: 1rem;
}

.rbc-event-preview-left {
  font-size: 0.8rem;
}

.rbc-event-preview-right {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 3.3rem;
}

.rbc-event {
  overflow: visible !important;
}

.rbc-month-row {
  overflow: visible !important;
}

.rbc-time-content {
  border: 0;
  overflow: visible;
}

.rbc-month-view .rbc-event {
  position: relative !important;
  width: 96% !important;
  margin-bottom: 0.2rem;
}

.rbc-header {
  color: var(--light-text-color);
  text-transform: uppercase;
}

.rbc-agenda-time-cell {
  border-left: 1px solid #ddd;
}

.arrow {
  position: absolute;
  right: 12px;
  width: 20px;
  height: 20px;
  display: block;
  bottom: -12px;
  background: white;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
  z-index: -999;
}

.hover-event-card {
  font-weight: 400;
  border-top: 4px solid var(--heavy-color);
  font-family: "Nunito Sans", sans-serif;
  line-height: 1.7;
  min-width: 360px;
  color: var(--heavy-text-color);
  fill: var(--heavy-text-color);
  background-color: #ffffff;
  opacity: 0;
  padding: 1rem;
  border-radius: 0px 0px 4px 4px;
  -moz-border-radius: 0px 0px 4px 4px;
  -webkit-border-radius: 0px 0px 4px 4px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
  will-change: opacity;
  display: none;
  z-index: 999;
  position: absolute;
  top: -290px;
  left: auto;
  right: 0;
  box-shadow: 0 50px 100px var(--light-grey-border-color),
    0 15px 35px var(--light-grey-border-color),
    0 5px 15px var(--light-grey-border-color);
  border: 1px solid var(--light-grey-border-color);
  border-top: 4px solid var(--heavy-color);

  font-size: 1rem;
}

.hover-event-card:hover {
  cursor: auto;
}

.rbc-selected {
  background-color: var(--medium-color) !important;
}

.rbc-selected .hover-event-card,
.trigger-enter .hover-event-card {
  display: block;
}

.rbc-selected .hover-event-card,
.trigger-enter-active .hover-event-card {
  opacity: 1;
}

.hover-event-card-doctor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.2;
}

.hover-event-card strong {
  color: var(--heavy-text-color);
}

.hover-event-card h3 {
  margin: 0;
}

.hover-event-card-time {
  font-weight: 700;
  font-size: 1.4rem;
  color: var(--heaviest-color);
}

.hover-event-card-consultorio {
  color: var(--normal-text-color);
  font-size: 0.8rem;
  font-weight: 300;
}

.hover-event-card-patient-phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hover-event-card-patient-info {
  margin-top: 0.5rem;
}

.heroicon-close {
  height: 100%;
  width: 100%;
}

.heroicon-close:hover {
  cursor: pointer;
}

.heroicon-close:hover .heroicon-ui {
  fill: var(--heaviest-red-color);
}

.heroicon-delete:hover .heroicon-ui,
.heroicon-edit:hover .heroicon-ui,
.heroicon-charge:hover .heroicon-ui {
  fill: #d5ebfa;
}

.heroicon-ui {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  fill: #1c3c52;
}

.hover-event-card-doctor-left {
  flex-shrink: 0;
}

.hover-event-card-doctor-right {
  height: 1.2rem;
  width: 1.2rem;
}

.rbc-toolbar {
  margin: 0;
  padding: 1rem;
}

.rbc-toolbar button {
  color: var(--normal-text-color);
  border-radius: 0px;
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
}

.rbc-toolbar button:hover,
.rbc-toolbar button:focus {
  border-radius: 0px;
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  box-shadow: none;
  background: none;
  border: none;
  color: var(--heavy-color);
  font-weight: 700;
  border-bottom: 3px solid var(--heavy-color);
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  box-shadow: none;
  background: none;
  border: none;
  color: var(--heavy-color);
  font-weight: 700;
  border-bottom: 3px solid var(--heavy-color);
}

.rbc-agenda-empty {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 25%;
}

.rbc-time-view .rbc-header,
.rbc-month-view .rbc-header {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 0;
}

.rbc-time-view .rbc-allday-cell {
  display: none;
}

.rbc-today {
  background-color: var(--light-secondary-color);
}

.rbc-header + .rbc-header {
  border: 0;
}

/*Font color styles*/

.rbc-date-cell {
  color: var(--light-text-color);
}

/* Border styles */

.rbc-calendar {
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid var(--light-grey-border-color);
  background-color: #ffffff;
  box-shadow: 0 1px 2px var(--light-grey-border-color);
}

.rbc-toolbar,
.rbc-row.rbc-month-header {
  border-bottom: 1px solid var(--light-grey-border-color);
}

.rbc-time-gutter .rbc-timeslot-group {
  border-top: 1px solid var(--light-grey-border-color) !important;
}

.rbc-row-bg:last-of-type {
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rbc-month-row + .rbc-month-row,
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid var(--light-grey-border-color);
}

.rbc-day-bg + .rbc-day-bg,
.rbc-time-content > * + * > * {
  border-left: 1px solid var(--light-grey-border-color);
}

.rbc-month-view,
.rbc-time-view {
  border: 0;
}

.rbc-timeslot-group {
  border: 0;
}

.rbc-current-time-indicator {
  border: 0;
  height: 3px;
  background-color: var(--heaviest-color);
}

.individual-top-div {
  background-color: var(--heaviest-color);
  min-height: 64px;
  border: 1px solid var(--light-grey-border-color);
  border-bottom: 0;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#edit-appointment-modal {
  overflow: hidden;
  z-index: 9;
  max-width: 600px;
  border-top: 4px solid var(--heavy-color);
  max-height: 30vh;
}

#edit-appointment-modal .modal-content {
  overflow-x: hidden;
  padding-right: 34px;
}

#appointment_name {
  text-align: right;
}

.has-text-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.has-text-left strong {
  font-weight: 700;
  font-size: 1.6rem;
}

.has-text-left span {
  flex: 0 0 auto;
}

#appointment_consultorio {
  flex: 0 0 20%;
}

#edit-appointment-cancel-button {
  color: var(--heaviest-color);
}

#efectivo-appointment-button {
  background-color: var(--heaviest-color);
  margin: 0 17px 0 17px;
  border-radius: 5px;
}

#tarjeta-appointment-button {
  background-color: var(--heaviest-secondary-color);
  margin: 0 0 0 17px;
  border-radius: 5px;
}

#efectivo-appointment-button:hover,
#tarjeta-appointment-button:hover {
  color: #fff;
}

#signin-modal,
#signup-modal {
  display: block;
}

#signin-modal form,
#signup-modal form {
  margin: 2rem;
}

#signin-modal h5,
#signup-modal h5 {
  margin: 1rem 0 2rem 0;
}

.input-field.button {
  min-height: 3rem;
  margin-top: 2rem;
}

.sidebar {
  min-width: 360px;
}

.handle-percentage {
  margin: 0 1rem;
  padding: 0 0 2rem 0;
}

.value {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-top: 6rem;
}

.rangeslider {
  box-shadow: none;
}
.rangeslider-horizontal {
  border-radius: 3px !important;
  box-shadow: none !important;
}

.rangeslider__fill {
  border-radius: 3px !important;
  background-color: var(--heaviest-color) !important;
}

.rangeslider__handle-tooltip {
  background-color: var(--heaviest-color) !important;
}

.rangeslider__handle-tooltip::after {
  border-top-color: var(--heaviest-color) !important;
}

.rangeslider__handle {
  width: 25px !important;
  height: 25px !important;
  border: 1px solid var(--light-grey-border-color) !important;
  box-shadow: none !important;
}

.rangeslider-horizontal .rangeslider__handle::after {
  display: none;
}

.edit-especialidades-container {
  min-width: 360px;
  border-radius: 2px;
  background-color: #fff;
  margin-bottom: 2rem;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
}

.edit-especialidades-container h5 {
  color: var(--heavy-text-color);
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1.2rem;
  margin: 0 0 2rem;
  border-bottom: 1px solid var(--light-grey-border-color);
}

.edit-especialidades-container > div {
  margin: 0 1rem;
}
#end,
#start {
  margin-bottom: 24px;
  border: 1px solid var(--light-grey-border-color);
  padding: 3px 12px;
  text-transform: capitalize;
  max-width: 300px;
  background: url(https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Calendar_icon_2.svg/2000px-Calendar_icon_2.svg.png)
    no-repeat scroll 7px 7px;
  background-color: white;
  background-size: 24px 24px;
  background-position-y: 50%;
  padding-left: 38px;
}

#start {
  border-radius: 5px 0px 0px 5px;
  -moz-border-radius: 5px 0px 0px 5px;
  -webkit-border-radius: 5px 0px 0px 5px;
  border-right: none;
}

#end {
  border-radius: 0px 5px 5px 0px;
  -moz-border-radius: 0px 5px 5px 0px;
  -webkit-border-radius: 0px 5px 5px 0px;
}

#generate-report {
  display: block;
}

.generate-report-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.generate-report-wrapper > div {
  display: flex;
}

.generate-report-wrapper > div input {
  flex: 0 0 200px;
}

.generate-report-outer-wrapper {
  max-height: 260px;
  min-width: 560px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
  background-color: #e5e5e5;
}

.generate-report-outer-wrapper h5 {
  background-color: #ffffff;
  color: var(--heavy-text-color);
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1.2rem;
  margin: 0 0 2rem 0;
  border-bottom: 1px solid var(--light-grey-border-color);
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  min-height: 48px;
}

#generate-report {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  display: inline-block;
  margin: 0 auto;
  width: 220px !important;
  background-color: var(--heaviest-color);
}

#generate-report:hover {
  color: #fff;
}

.patients-dashboard table {
  width: 60%;
  flex: 0 0 60%;
  background-color: #ffffff;
  margin-bottom: 2rem;
  border-radius: 5px;
  border-collapse: separate;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
}

.patients-dashboard table th,
.patients-dashboard table td {
  padding: 1rem 0 1rem 2rem;
  border-collapse: separate;
  border-collapse: initial;
  border-bottom: 1px solid var(--light-grey-border-color);
}

.patients-dashboard {
  width: 96%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}

.td-icon {
  padding-right: 1rem !important;
}

.td-icon i:hover {
  cursor: pointer;
}

