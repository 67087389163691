#add-doctor-modal {
  width: 70%;
  display: block;
  background-color: #ffffff;
  border-radius: 5px;
  height: 80vh;
  max-height: 80vh;
}

#add-doctor-modal .modal-content {
  overflow-x: hidden;
  padding-right: 34px;
}

.ScrollbarsCustom-track {
  right: 2px !important;
}

.select-input {
  font-family: "Nunito Sans", sans-serif;
  border: 0;
  box-shadow: none;
  border-radius: 4px;
}

.has-icon-centered {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.has-icon-centered > *:last-child {
  flex: 0 0 92%;
}

.select-input > div:first-of-type {
  border-color: var(--light-grey-border-color) !important;
  box-shadow: none !important;
}

.select-input input {
  max-height: 1.75rem;
}

.select-input input {
  border-bottom: none !important;
  box-shadow: none !important;
}

.input-field .prefix.active {
  color: var(--heaviest-color);
}

#add-doctor-cancel-button {
  color: var(--heaviest-color);
}

#add-doctor-button {
  background-color: var(--heaviest-color);
  margin: 0 17px 0 17px;
  border-radius: 5px;
}

#add-doctor-button:hover {
  color: #fff;
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid var(--heaviest-color);
  -webkit-box-shadow: 0 1px 0 0 var(--heaviest-color);
  box-shadow: 0 1px 0 0 var(--heaviest-color);
}

input:not([type]):disabled,
input:not([type])[readonly="readonly"],
input[type="text"]:not(.browser-default):disabled,
input[type="text"]:not(.browser-default)[readonly="readonly"],
input[type="password"]:not(.browser-default):disabled,
input[type="password"]:not(.browser-default)[readonly="readonly"],
input[type="email"]:not(.browser-default):disabled,
input[type="email"]:not(.browser-default)[readonly="readonly"],
input[type="url"]:not(.browser-default):disabled,
input[type="url"]:not(.browser-default)[readonly="readonly"],
input[type="time"]:not(.browser-default):disabled,
input[type="time"]:not(.browser-default)[readonly="readonly"],
input[type="date"]:not(.browser-default):disabled,
input[type="date"]:not(.browser-default)[readonly="readonly"],
input[type="datetime"]:not(.browser-default):disabled,
input[type="datetime"]:not(.browser-default)[readonly="readonly"],
input[type="datetime-local"]:not(.browser-default):disabled,
input[type="datetime-local"]:not(.browser-default)[readonly="readonly"],
input[type="tel"]:not(.browser-default):disabled,
input[type="tel"]:not(.browser-default)[readonly="readonly"],
input[type="number"]:not(.browser-default):disabled,
input[type="number"]:not(.browser-default)[readonly="readonly"],
input[type="search"]:not(.browser-default):disabled,
input[type="search"]:not(.browser-default)[readonly="readonly"],
textarea.materialize-textarea:disabled,
textarea.materialize-textarea[readonly="readonly"] {
  color: rgba(0, 0, 0, 0.42);
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
}

input:not([type]):disabled + label,
input:not([type])[readonly="readonly"] + label,
input[type="text"]:not(.browser-default):disabled + label,
input[type="text"]:not(.browser-default)[readonly="readonly"] + label,
input[type="password"]:not(.browser-default):disabled + label,
input[type="password"]:not(.browser-default)[readonly="readonly"] + label,
input[type="email"]:not(.browser-default):disabled + label,
input[type="email"]:not(.browser-default)[readonly="readonly"] + label,
input[type="url"]:not(.browser-default):disabled + label,
input[type="url"]:not(.browser-default)[readonly="readonly"] + label,
input[type="time"]:not(.browser-default):disabled + label,
input[type="time"]:not(.browser-default)[readonly="readonly"] + label,
input[type="date"]:not(.browser-default):disabled + label,
input[type="date"]:not(.browser-default)[readonly="readonly"] + label,
input[type="datetime"]:not(.browser-default):disabled + label,
input[type="datetime"]:not(.browser-default)[readonly="readonly"] + label,
input[type="datetime-local"]:not(.browser-default):disabled + label,
input[type="datetime-local"]:not(.browser-default)[readonly="readonly"] + label,
input[type="tel"]:not(.browser-default):disabled + label,
input[type="tel"]:not(.browser-default)[readonly="readonly"] + label,
input[type="number"]:not(.browser-default):disabled + label,
input[type="number"]:not(.browser-default)[readonly="readonly"] + label,
input[type="search"]:not(.browser-default):disabled + label,
input[type="search"]:not(.browser-default)[readonly="readonly"] + label,
textarea.materialize-textarea:disabled + label,
textarea.materialize-textarea[readonly="readonly"] + label {
  color: rgba(0, 0, 0, 0.42);
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid var(--heaviest-color);
  -webkit-box-shadow: 0 1px 0 0 var(--heaviest-color);
  box-shadow: 0 1px 0 0 var(--heaviest-color);
}

input:not([type]):focus:not([readonly]) + label,
input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime-local"]:not(.browser-default):focus:not([readonly])
  + label,
input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
input[type="number"]:not(.browser-default):focus:not([readonly]) + label,
input[type="search"]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: var(--heaviest-color);
}

input:not([type]):focus.valid ~ label,
input[type="text"]:not(.browser-default):focus.valid ~ label,
input[type="password"]:not(.browser-default):focus.valid ~ label,
input[type="email"]:not(.browser-default):focus.valid ~ label,
input[type="url"]:not(.browser-default):focus.valid ~ label,
input[type="time"]:not(.browser-default):focus.valid ~ label,
input[type="date"]:not(.browser-default):focus.valid ~ label,
input[type="datetime"]:not(.browser-default):focus.valid ~ label,
input[type="datetime-local"]:not(.browser-default):focus.valid ~ label,
input[type="tel"]:not(.browser-default):focus.valid ~ label,
input[type="number"]:not(.browser-default):focus.valid ~ label,
input[type="search"]:not(.browser-default):focus.valid ~ label,
textarea.materialize-textarea:focus.valid ~ label {
  color: var(--heaviest-color);
}

input:not([type]):focus.invalid ~ label,
input[type="text"]:not(.browser-default):focus.invalid ~ label,
input[type="password"]:not(.browser-default):focus.invalid ~ label,
input[type="email"]:not(.browser-default):focus.invalid ~ label,
input[type="url"]:not(.browser-default):focus.invalid ~ label,
input[type="time"]:not(.browser-default):focus.invalid ~ label,
input[type="date"]:not(.browser-default):focus.invalid ~ label,
input[type="datetime"]:not(.browser-default):focus.invalid ~ label,
input[type="datetime-local"]:not(.browser-default):focus.invalid ~ label,
input[type="tel"]:not(.browser-default):focus.invalid ~ label,
input[type="number"]:not(.browser-default):focus.invalid ~ label,
input[type="search"]:not(.browser-default):focus.invalid ~ label,
textarea.materialize-textarea:focus.invalid ~ label {
  color: #f44336;
}

input:not([type]).validate + label,
input[type="text"]:not(.browser-default).validate + label,
input[type="password"]:not(.browser-default).validate + label,
input[type="email"]:not(.browser-default).validate + label,
input[type="url"]:not(.browser-default).validate + label,
input[type="time"]:not(.browser-default).validate + label,
input[type="date"]:not(.browser-default).validate + label,
input[type="datetime"]:not(.browser-default).validate + label,
input[type="datetime-local"]:not(.browser-default).validate + label,
input[type="tel"]:not(.browser-default).validate + label,
input[type="number"]:not(.browser-default).validate + label,
input[type="search"]:not(.browser-default).validate + label,
textarea.materialize-textarea.validate + label {
  width: 100%;
}

/* Validation Sass Placeholders */
input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type="text"]:not(.browser-default),
input.valid[type="text"]:not(.browser-default):focus,
input.valid[type="password"]:not(.browser-default),
input.valid[type="password"]:not(.browser-default):focus,
input.valid[type="email"]:not(.browser-default),
input.valid[type="email"]:not(.browser-default):focus,
input.valid[type="url"]:not(.browser-default),
input.valid[type="url"]:not(.browser-default):focus,
input.valid[type="time"]:not(.browser-default),
input.valid[type="time"]:not(.browser-default):focus,
input.valid[type="date"]:not(.browser-default),
input.valid[type="date"]:not(.browser-default):focus,
input.valid[type="datetime"]:not(.browser-default),
input.valid[type="datetime"]:not(.browser-default):focus,
input.valid[type="datetime-local"]:not(.browser-default),
input.valid[type="datetime-local"]:not(.browser-default):focus,
input.valid[type="tel"]:not(.browser-default),
input.valid[type="tel"]:not(.browser-default):focus,
input.valid[type="number"]:not(.browser-default),
input.valid[type="number"]:not(.browser-default):focus,
input.valid[type="search"]:not(.browser-default),
input.valid[type="search"]:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid #4caf50;
  -webkit-box-shadow: 0 1px 0 0 #4caf50;
  box-shadow: 0 1px 0 0 #4caf50;
}

input.invalid:not([type]),
input.invalid:not([type]):focus,
input.invalid[type="text"]:not(.browser-default),
input.invalid[type="text"]:not(.browser-default):focus,
input.invalid[type="password"]:not(.browser-default),
input.invalid[type="password"]:not(.browser-default):focus,
input.invalid[type="email"]:not(.browser-default),
input.invalid[type="email"]:not(.browser-default):focus,
input.invalid[type="url"]:not(.browser-default),
input.invalid[type="url"]:not(.browser-default):focus,
input.invalid[type="time"]:not(.browser-default),
input.invalid[type="time"]:not(.browser-default):focus,
input.invalid[type="date"]:not(.browser-default),
input.invalid[type="date"]:not(.browser-default):focus,
input.invalid[type="datetime"]:not(.browser-default),
input.invalid[type="datetime"]:not(.browser-default):focus,
input.invalid[type="datetime-local"]:not(.browser-default),
input.invalid[type="datetime-local"]:not(.browser-default):focus,
input.invalid[type="tel"]:not(.browser-default),
input.invalid[type="tel"]:not(.browser-default):focus,
input.invalid[type="number"]:not(.browser-default),
input.invalid[type="number"]:not(.browser-default):focus,
input.invalid[type="search"]:not(.browser-default),
input.invalid[type="search"]:not(.browser-default):focus,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea.invalid:focus,
.select-wrapper.invalid > input.select-dropdown,
.select-wrapper.invalid > input.select-dropdown:focus {
  border-bottom: 1px solid #f44336;
  -webkit-box-shadow: 0 1px 0 0 #f44336;
  box-shadow: 0 1px 0 0 #f44336;
}

input:not([type]).valid ~ .helper-text[data-success],
input:not([type]):focus.valid ~ .helper-text[data-success],
input:not([type]).invalid ~ .helper-text[data-error],
input:not([type]):focus.invalid ~ .helper-text[data-error],
input[type="text"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="text"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="text"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="text"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="password"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="password"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="password"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="password"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="email"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="email"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="email"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="email"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="url"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="url"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="url"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="url"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="time"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="time"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="time"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="time"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="date"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="date"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="date"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="date"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="datetime"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="datetime"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="datetime"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="datetime"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="datetime-local"]:not(.browser-default).valid
  ~ .helper-text[data-success],
input[type="datetime-local"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="datetime-local"]:not(.browser-default).invalid
  ~ .helper-text[data-error],
input[type="datetime-local"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="tel"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="tel"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="tel"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="tel"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="number"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="number"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="number"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="number"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
input[type="search"]:not(.browser-default).valid ~ .helper-text[data-success],
input[type="search"]:not(.browser-default):focus.valid
  ~ .helper-text[data-success],
input[type="search"]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type="search"]:not(.browser-default):focus.invalid
  ~ .helper-text[data-error],
textarea.materialize-textarea.valid ~ .helper-text[data-success],
textarea.materialize-textarea:focus.valid ~ .helper-text[data-success],
textarea.materialize-textarea.invalid ~ .helper-text[data-error],
textarea.materialize-textarea:focus.invalid ~ .helper-text[data-error],
.select-wrapper.valid .helper-text[data-success],
.select-wrapper.invalid ~ .helper-text[data-error] {
  color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

input:not([type]).valid ~ .helper-text:after,
input:not([type]):focus.valid ~ .helper-text:after,
input[type="text"]:not(.browser-default).valid ~ .helper-text:after,
input[type="text"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="password"]:not(.browser-default).valid ~ .helper-text:after,
input[type="password"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="email"]:not(.browser-default).valid ~ .helper-text:after,
input[type="email"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="url"]:not(.browser-default).valid ~ .helper-text:after,
input[type="url"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="time"]:not(.browser-default).valid ~ .helper-text:after,
input[type="time"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="date"]:not(.browser-default).valid ~ .helper-text:after,
input[type="date"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="datetime"]:not(.browser-default).valid ~ .helper-text:after,
input[type="datetime"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="datetime-local"]:not(.browser-default).valid ~ .helper-text:after,
input[type="datetime-local"]:not(.browser-default):focus.valid
  ~ .helper-text:after,
input[type="tel"]:not(.browser-default).valid ~ .helper-text:after,
input[type="tel"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="number"]:not(.browser-default).valid ~ .helper-text:after,
input[type="number"]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type="search"]:not(.browser-default).valid ~ .helper-text:after,
input[type="search"]:not(.browser-default):focus.valid ~ .helper-text:after,
textarea.materialize-textarea.valid ~ .helper-text:after,
textarea.materialize-textarea:focus.valid ~ .helper-text:after,
.select-wrapper.valid ~ .helper-text:after {
  content: attr(data-success);
  color: var(--heaviest-color);
}

input:not([type]).invalid ~ .helper-text:after,
input:not([type]):focus.invalid ~ .helper-text:after,
input[type="text"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="text"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="password"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="password"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="email"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="email"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="url"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="url"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="time"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="time"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="date"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="date"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="datetime"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="datetime"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="datetime-local"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="datetime-local"]:not(.browser-default):focus.invalid
  ~ .helper-text:after,
input[type="tel"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="tel"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="number"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="number"]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type="search"]:not(.browser-default).invalid ~ .helper-text:after,
input[type="search"]:not(.browser-default):focus.invalid ~ .helper-text:after,
textarea.materialize-textarea.invalid ~ .helper-text:after,
textarea.materialize-textarea:focus.invalid ~ .helper-text:after,
.select-wrapper.invalid ~ .helper-text:after {
  content: attr(data-error);
  color: #f44336;
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
  background-color: inherit;
}
