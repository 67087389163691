body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spinner-outer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: calc(90vh - 6rem);
}

.sk-spinner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1rem;
}
