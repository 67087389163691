.sidebar {
  color: var(--heavy-text-color);
  flex: 0 0 30%;
  min-width: 320px;
}

.sidebar-no-dates-message {
  margin: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
}

.sidebar-general-info {
  overflow: hidden;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 2rem;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
}

.sidebar-general-info-appointments {
  margin: 1rem 2rem;
  display: flex;
  align-items: center;
}

.sidebar-general-info-money-made,
.sidebar-general-info-money-give {
  margin-bottom: 0.5rem;
}

.sidebar-general-info-money-hide {
  padding: 0.4rem 0.8rem;
  border-radius: 5px;
  color: #212121;
}

.sidebar-general-info-money-hide.background {
  background-color: #ccc;
}

.sidebar-general-info-money-hide:hover {
  cursor: pointer;
}

.sidebar-general-info-money-hide-label {
  font-size: 0.8rem;
  display: none;
}

.sidebar-general-info-money-hide-label.show {
  display: block;
}

.sidebar-general-info-money {
  margin: 1rem 2rem;
}

.sidebar-general-info-money > span {
  line-height: 1.7;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-general-info-money-give-number,
.sidebar-general-info-money-made-number {
  display: none;
  font-weight: 600;
}

.sidebar-general-info-money-give-number.show,
.sidebar-general-info-money-made-number.show {
  display: block;
  font-weight: 600;
}

.sidebar-general-info-money-give-number {
  color: var(--heaviest-red-color);
}

.sidebar-general-info-money-made-number {
  color: #02b55f;
}

.sidebar-general-info-appointments-completed {
  color: var(--normal-text-color);
  font-size: 2.4rem;
  font-weight: 300;
  margin-right: 0.5rem;
}

.sidebar-general-info-appointments-total {
  color: var(--normal-text-color);
  font-size: 2.4rem;
  font-weight: 300;
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.sidebar-next-appointments {
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
  background-color: #e5e5e5;
}

.sidebar-appointments {
  margin: 1rem 2rem;
}

.sidebar h5 {
  background-color: #ffffff;
  color: var(--heavy-text-color);
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1.2rem;
  margin: 0 0 2rem 0;
  border-bottom: 1px solid var(--light-grey-border-color);
}

.sidebar-appointments-completed {
  font-size: 1.2rem;
}

.sidebar-appointments-completed.green {
  color: var(--heavy-red-color);
}

.sidebar-appointments-completed.red {
  color: var(--heavy-red-color);
}

.sidebar-appointments-left {
  font-size: 1.6rem;
  font-weight: 700;
  color: #52b3bc;
}

.sidebar-individual-appointment {
  background-color: #fff;
  overflow: hidden;
  border-style: inset;
  margin: 1rem;
  border-radius: 5px;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  padding: 0px 24px 0px 0px;
}

.sidebar-individual-appointment-buttons {
  background-color: #f8f9fa;
  width: 48px;
  display: flex;
  height: 120px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-right: 24px;
}

.sidebar-individual-appointment-buttons svg.no-hover * {
  fill: hsl(202, 12%, 46%) !important;
}

.sidebar-individual-appointment-buttons svg * {
  box-shadow: inset 0 1px 3px var(--light-shadow-black-color),
    0 -2px 0 var(--light-shadow-white-color);
  fill: hsl(202, 12%, 46%);
}

.sidebar-individual-appointment-buttons svg:hover *,
.sidebar-individual-appointment-buttons svg.selected * {
  fill: var(--heaviest-color);
}

.sidebar-individual-appointment-buttons svg.no-hover {
  cursor: auto !important;
}

.sidebar-individual-appointment-buttons svg:hover {
  cursor: pointer;
}

.sidebar-individual-appointment:first-of-type {
  margin-top: -1rem;
}

.sidebar-individual-appointment-details {
  padding: 1rem;
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.sidebar-individual-appointment-details-name {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.sidebar-individual-appointment-details-time {
  font-size: 0.8rem;
  color: var(--light-text-color);
  font-weight: 300;
}

.sidebar-individual-appointment-patient {
  margin: 0.2rem 0 0.8rem 0;
  color: var(--normal-text-color);
}
