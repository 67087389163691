/*
  Declare color variables
*/

:root {
  --light-shadow-black-color: hsla(0, 0%, 0%, 0.2);
  --light-shadow-white-color: hsla(0, 0%, 100%, 0.2);
  --light-color: #d9edfa;
  --medium-color: #a6d0ec;
  --heavy-color: #78b2d8;
  --heaviest-color: #2e7db2;
  --light-color-two: #92ebeb;
  --medium-color-two: #64d8d8;
  --light-color-three: #dcf4c7;
  --medium-color-three: #c3eaa1;
  --light-color-four: #d3fbe8;
  --medium-color-four: #a4efcb;
  --light-color-five: #d9dafb;
  --medium-color-five: #aeb1ef;
  --light-color-six: #d2f9f8;
  --medium-color-six: #a1ebe6;
  --light-color-seven: #feced7;
  --medium-color-seven: #fc9caf;
  --light-color-eight: #ffeaae;
  --medium-color-eight: #ffe087;
  --light-color-nine: #ffc3ae;
  --medium-color-nine: #ffa687;
  --light-color-ten: #cb8aa8;
  --medium-color-ten: #aa5a7e;
  --light-color-fourteen: #ffe6d1;
  --medium-color-fourteen: #facfad;
  --light-secondary-color: #b4e9ee;
  --heaviest-secondary-color: #52b3bc;
  --light-red-color: #fedae3;
  --heavy-red-color: #f25a80;
  --heaviest-red-color: #c43200;
  --light-grey-color: #f2f2f2;
  --light-white-color: #fffffffb;
  --normal-text-color: #58575e;
  --light-text-color: #64727b;
  --heavy-text-color: #333333;
  --light-grey-border-color: #64727b40;
  background-color: rgba(211, 236, 242, 0.2);
}

.hide {
  display: none;
}

.show {
  display: block;
}

body {
  margin-bottom: 20vh;
}

body.loading {
  cursor: progress;
}

body,
button {
  font-family: "Nunito Sans", sans-serif !important;
  color: var(--normal-text-color);
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--heavy-text-color);
}

a {
  color: var(--normal-text-color);
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: var(--heavy-text-color);
}
