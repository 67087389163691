.nav-wrapper {
  min-height: 4rem;
  background-color: #fff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
}

.nav-wrapper .nav-links {
  flex: 1 1 auto;
}

.nav-wrapper .nav-links .right a:not(.btn) {
  font-weight: 400;
  color: var(--normal-text-color);
}

.nav-links a:hover {
  background-color: transparent !important;
  color: var(--heaviest-color) !important;
}

.nav-links a.current {
  font-weight: 700 !important;
  font-size: 0.95rem;
  box-shadow: inset 0 -2px 0 var(--heaviest-color);
  color: var(--heaviest-color) !important;
}

.nav-wrapper img {
  max-height: 3rem;
  margin-left: 2rem;
}

.nav-wrapper .brand-logo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .nav-wrapper img {
    margin-left: 0;
  }
}
