.sidebar {
  min-width: 360px;
}

.handle-percentage {
  margin: 0 1rem;
  padding: 0 0 2rem 0;
}

.value {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-top: 6rem;
}

.rangeslider {
  box-shadow: none;
}
.rangeslider-horizontal {
  border-radius: 3px !important;
  box-shadow: none !important;
}

.rangeslider__fill {
  border-radius: 3px !important;
  background-color: var(--heaviest-color) !important;
}

.rangeslider__handle-tooltip {
  background-color: var(--heaviest-color) !important;
}

.rangeslider__handle-tooltip::after {
  border-top-color: var(--heaviest-color) !important;
}

.rangeslider__handle {
  width: 25px !important;
  height: 25px !important;
  border: 1px solid var(--light-grey-border-color) !important;
  box-shadow: none !important;
}

.rangeslider-horizontal .rangeslider__handle::after {
  display: none;
}
