#end,
#start {
  margin-bottom: 24px;
  border: 1px solid var(--light-grey-border-color);
  padding: 3px 12px;
  text-transform: capitalize;
  max-width: 300px;
  background: url(https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Calendar_icon_2.svg/2000px-Calendar_icon_2.svg.png)
    no-repeat scroll 7px 7px;
  background-color: white;
  background-size: 24px 24px;
  background-position-y: 50%;
  padding-left: 38px;
}

#start {
  border-radius: 5px 0px 0px 5px;
  -moz-border-radius: 5px 0px 0px 5px;
  -webkit-border-radius: 5px 0px 0px 5px;
  border-right: none;
}

#end {
  border-radius: 0px 5px 5px 0px;
  -moz-border-radius: 0px 5px 5px 0px;
  -webkit-border-radius: 0px 5px 5px 0px;
}

#generate-report {
  display: block;
}

.generate-report-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.generate-report-wrapper > div {
  display: flex;
}

.generate-report-wrapper > div input {
  flex: 0 0 200px;
}

.generate-report-outer-wrapper {
  max-height: 260px;
  min-width: 560px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
  background-color: #e5e5e5;
}

.generate-report-outer-wrapper h5 {
  background-color: #ffffff;
  color: var(--heavy-text-color);
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1.2rem;
  margin: 0 0 2rem 0;
  border-bottom: 1px solid var(--light-grey-border-color);
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  min-height: 48px;
}

#generate-report {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  display: inline-block;
  margin: 0 auto;
  width: 220px !important;
  background-color: var(--heaviest-color);
}

#generate-report:hover {
  color: #fff;
}
