.big-calendar-wrapper {
  min-height: 580px;
  flex: 0 0 65%;
  min-width: 320px;
  font-size: 14px;
}

.rbc-addons-dnd-resizable {
  width: 100%;
}

button:hover {
  cursor: pointer;
}

.not-business {
  background-color: var(--light-red-color);
}

.not-business:hover {
  cursor: pointer;
}

.rbc-timeslot-group {
  min-height: 50px;
}

.not-business:hover {
  cursor: not-allowed;
}

.rbc-day-slot .rbc-events-container {
  margin: 0;
}

.rbc-event strong {
  color: #0f1c20;
}

.rbc-event-content {
  display: flex;
  align-items: center;
}

.rbc-event-content > div {
  width: 100%;
  height: 100%;
}

/* Normal */

.rbc-event {
  min-width: 100px;
  padding: 0;
  color: var(--heavy-text-color);
  fill: var(--heavy-text-color);
  font-size: 0.9rem;
  font-weight: 300;
  border: 0 !important;
  border-radius: 0px !important;
  background-color: var(--light-color);
  box-shadow: 0px 1px 2px var(--light-grey-border-color);
  margin-bottom: 2px;
  min-height: 20px;
  transition: transform 0.5s ease-in-out;
  z-index: 9;
}

.rbc-event:focus {
  outline: none;
}

.rbc-event-content > div.two {
  background-color: var(--light-color-two);
}

.rbc-event-content > div.three {
  background-color: var(--light-color-three);
}

.rbc-event-content > div.four {
  background-color: var(--light-color-four);
}

.rbc-event-content > div.five {
  background-color: var(--light-color-five);
}

.rbc-event-content > div.six {
  background-color: var(--light-color-six);
}

.rbc-event-content > div.seven {
  background-color: var(--light-color-seven);
}

.rbc-event-content > div.eight {
  background-color: var(--light-color-eight);
}

.rbc-event-content > div.nine {
  background-color: var(--light-color-nine);
}

.rbc-event-content > div.ten {
  background-color: var(--light-color-ten);
}

.rbc-event-content > div.fourteen {
  background-color: var(--light-color-fourteen);
}

/* Hover and finished*/

.rbc-event:hover {
  z-index: 9999999999;
}

.rbc-event:hover,
.rbc-event-content > div.finished {
  background-color: var(--medium-color) !important;
}

.rbc-event-content > div.two:hover,
.rbc-event-content > div.finished.two {
  background-color: var(--medium-color-two) !important;
}

.rbc-event-content > div.three:hover,
.rbc-event-content > div.finished.three {
  background-color: var(--medium-color-three) !important;
}

.rbc-event-content > div.four:hover,
.rbc-event-content > div.finished.four {
  background-color: var(--medium-color-four) !important;
}

.rbc-event-content > div.five:hover,
.rbc-event-content > div.finished.five {
  background-color: var(--medium-color-five) !important;
}

.rbc-event-content > div.six:hover,
.rbc-event-content > div.finished.six {
  background-color: var(--medium-color-six) !important;
}

.rbc-event-content > div.seven:hover,
.rbc-event-content > div.finished.seven {
  background-color: var(--medium-color-seven) !important;
}

.rbc-event-content > div.eight:hover,
.rbc-event-content > div.finished.eight {
  background-color: var(--medium-color-eight) !important;
}

.rbc-event-content > div.nine:hover,
.rbc-event-content > div.finished.nine {
  background-color: var(--medium-color-nine) !important;
}

.rbc-event-content > div.ten:hover,
.rbc-event-content > div.finished.ten {
  background-color: var(--medium-color-ten) !important;
}

.rbc-event-content > div.fourteen:hover,
.rbc-event-content > div.finished.fourteen {
  background-color: var(--medium-color-fourteen) !important;
}

/* Confirmed */

.rbc-event-content .confirmed {
  border-left: 0.25rem solid var(--medium-color) !important;
}

.rbc-event-content .confirmed.two {
  border-left: 0.25rem solid var(--medium-color-two) !important;
}

.rbc-event-content .confirmed.three {
  border-left: 0.25rem solid var(--medium-color-three) !important;
}

.rbc-event-content .confirmed.four {
  border-left: 0.25rem solid var(--medium-color-four) !important;
}

.rbc-event-content .confirmed.five {
  border-left: 0.25rem solid var(--medium-color-five) !important;
}

.rbc-event-content .confirmed.six {
  border-left: 0.25rem solid var(--medium-color-six) !important;
}

.rbc-event-content .confirmed.seven {
  border-left: 0.25rem solid var(--medium-color-seven) !important;
}

.rbc-event-content .confirmed.eight {
  border-left: 0.25rem solid var(--medium-color-eight) !important;
}

.rbc-event-content .confirmed.nine {
  border-left: 0.25rem solid var(--medium-color-nine) !important;
}

.rbc-event-content .confirmed.ten {
  border-left: 0.25rem solid var(--medium-color-ten) !important;
}

.rbc-event-content .confirmed.fourteen {
  border-left: 0.25rem solid var(--medium-color-fourteen) !important;
}

.rbc-event-label {
  display: none;
}

.rbc-event-preview {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 4px;
}

.rbc-event-preview svg {
  height: 1rem;
  width: 1rem;
}

.rbc-event-preview-left {
  font-size: 0.8rem;
}

.rbc-event-preview-right {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 3.3rem;
}

.rbc-event {
  overflow: visible !important;
}

.rbc-month-row {
  overflow: visible !important;
}

.rbc-time-content {
  border: 0;
  overflow: visible;
}

.rbc-month-view .rbc-event {
  position: relative !important;
  width: 96% !important;
  margin-bottom: 0.2rem;
}

.rbc-header {
  color: var(--light-text-color);
  text-transform: uppercase;
}

.rbc-agenda-time-cell {
  border-left: 1px solid #ddd;
}

.arrow {
  position: absolute;
  right: 12px;
  width: 20px;
  height: 20px;
  display: block;
  bottom: -12px;
  background: white;
  transform: translateY(-50%) rotate(45deg);
  z-index: -999;
}

.hover-event-card {
  font-weight: 400;
  border-top: 4px solid var(--heavy-color);
  font-family: "Nunito Sans", sans-serif;
  line-height: 1.7;
  min-width: 360px;
  color: var(--heavy-text-color);
  fill: var(--heavy-text-color);
  background-color: #ffffff;
  opacity: 0;
  padding: 1rem;
  border-radius: 0px 0px 4px 4px;
  -moz-border-radius: 0px 0px 4px 4px;
  -webkit-border-radius: 0px 0px 4px 4px;
  transition: all 0.5s ease-in-out;
  transform: translateY(100px);
  will-change: opacity;
  display: none;
  z-index: 999;
  position: absolute;
  top: -290px;
  left: auto;
  right: 0;
  box-shadow: 0 50px 100px var(--light-grey-border-color),
    0 15px 35px var(--light-grey-border-color),
    0 5px 15px var(--light-grey-border-color);
  border: 1px solid var(--light-grey-border-color);
  border-top: 4px solid var(--heavy-color);

  font-size: 1rem;
}

.hover-event-card:hover {
  cursor: auto;
}

.rbc-selected {
  background-color: var(--medium-color) !important;
}

.rbc-selected .hover-event-card,
.trigger-enter .hover-event-card {
  display: block;
}

.rbc-selected .hover-event-card,
.trigger-enter-active .hover-event-card {
  opacity: 1;
}

.hover-event-card-doctor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.2;
}

.hover-event-card strong {
  color: var(--heavy-text-color);
}

.hover-event-card h3 {
  margin: 0;
}

.hover-event-card-time {
  font-weight: 700;
  font-size: 1.4rem;
  color: var(--heaviest-color);
}

.hover-event-card-consultorio {
  color: var(--normal-text-color);
  font-size: 0.8rem;
  font-weight: 300;
}

.hover-event-card-patient-phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hover-event-card-patient-info {
  margin-top: 0.5rem;
}

.heroicon-close {
  height: 100%;
  width: 100%;
}

.heroicon-close:hover {
  cursor: pointer;
}

.heroicon-close:hover .heroicon-ui {
  fill: var(--heaviest-red-color);
}

.heroicon-delete:hover .heroicon-ui,
.heroicon-edit:hover .heroicon-ui,
.heroicon-charge:hover .heroicon-ui {
  fill: #d5ebfa;
}

.heroicon-ui {
  transition: all 0.2s ease-in-out;
  fill: #1c3c52;
}

.hover-event-card-doctor-left {
  flex-shrink: 0;
}

.hover-event-card-doctor-right {
  height: 1.2rem;
  width: 1.2rem;
}

.rbc-toolbar {
  margin: 0;
  padding: 1rem;
}

.rbc-toolbar button {
  color: var(--normal-text-color);
  border-radius: 0px;
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
}

.rbc-toolbar button:hover,
.rbc-toolbar button:focus {
  border-radius: 0px;
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  box-shadow: none;
  background: none;
  border: none;
  color: var(--heavy-color);
  font-weight: 700;
  border-bottom: 3px solid var(--heavy-color);
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  box-shadow: none;
  background: none;
  border: none;
  color: var(--heavy-color);
  font-weight: 700;
  border-bottom: 3px solid var(--heavy-color);
}

.rbc-agenda-empty {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 25%;
}

.rbc-time-view .rbc-header,
.rbc-month-view .rbc-header {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 0;
}

.rbc-time-view .rbc-allday-cell {
  display: none;
}

.rbc-today {
  background-color: var(--light-secondary-color);
}

.rbc-header + .rbc-header {
  border: 0;
}

/*Font color styles*/

.rbc-date-cell {
  color: var(--light-text-color);
}

/* Border styles */

.rbc-calendar {
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid var(--light-grey-border-color);
  background-color: #ffffff;
  box-shadow: 0 1px 2px var(--light-grey-border-color);
}

.rbc-toolbar,
.rbc-row.rbc-month-header {
  border-bottom: 1px solid var(--light-grey-border-color);
}

.rbc-time-gutter .rbc-timeslot-group {
  border-top: 1px solid var(--light-grey-border-color) !important;
}

.rbc-row-bg:last-of-type {
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rbc-month-row + .rbc-month-row,
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid var(--light-grey-border-color);
}

.rbc-day-bg + .rbc-day-bg,
.rbc-time-content > * + * > * {
  border-left: 1px solid var(--light-grey-border-color);
}

.rbc-month-view,
.rbc-time-view {
  border: 0;
}

.rbc-timeslot-group {
  border: 0;
}

.rbc-current-time-indicator {
  border: 0;
  height: 3px;
  background-color: var(--heaviest-color);
}

.individual-top-div {
  background-color: var(--heaviest-color);
  min-height: 64px;
  border: 1px solid var(--light-grey-border-color);
  border-bottom: 0;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
