#signin-modal,
#signup-modal {
  display: block;
}

#signin-modal form,
#signup-modal form {
  margin: 2rem;
}

#signin-modal h5,
#signup-modal h5 {
  margin: 1rem 0 2rem 0;
}

.input-field.button {
  min-height: 3rem;
  margin-top: 2rem;
}
