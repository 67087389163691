.doctor-select-wrapper {
  background-color: var(--heaviest-color);
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--light-grey-border-color);
  border-bottom: 0;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.doctor-select {
  max-width: 300px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8rem;
  width: 100%;
  color: var(--normal-text-color);
  background-color: white;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: none;
  border-radius: 4px;
}

.doctor-select-wrapper > div a {
  margin-right: 1rem;
}

.doctor-select-wrapper > div a path {
  fill: #ffffff;
}

.doctor-select > div {
  min-height: auto;
  background-color: inherit;
  border: 0;
}

.doctor-select > span {
  display: none;
  border: 0;
  outline: none;
}

.react-select__menu {
  z-index: 99999999999 !important;
}
