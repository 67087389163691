.edit-especialidades-container {
  min-width: 360px;
  border-radius: 2px;
  background-color: #fff;
  margin-bottom: 2rem;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
}

.edit-especialidades-container h5 {
  color: var(--heavy-text-color);
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1.2rem;
  margin: 0 0 2rem;
  border-bottom: 1px solid var(--light-grey-border-color);
}

.edit-especialidades-container > div {
  margin: 0 1rem;
}