.patients-dashboard table {
  width: 60%;
  flex: 0 0 60%;
  background-color: #ffffff;
  margin-bottom: 2rem;
  border-radius: 5px;
  border-collapse: separate;
  border: 1px solid var(--light-grey-border-color);
  box-shadow: 0 1px 2px var(--light-grey-border-color);
}

.patients-dashboard table th,
.patients-dashboard table td {
  padding: 1rem 0 1rem 2rem;
  border-collapse: initial;
  border-bottom: 1px solid var(--light-grey-border-color);
}

.patients-dashboard {
  width: 96%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}

.td-icon {
  padding-right: 1rem !important;
}

.td-icon i:hover {
  cursor: pointer;
}
